<template>
  <div class="telebooking">
    <div class="calling">
      <header>{{ headerText }}</header>
      <div class="form-main">
        <el-form
          ref="ruleForm"
          :model="form"
          :disabled="payStatus ? true : false"
          :rules="rules"
          label-position="right"
          label-width="120px"
        >
          <div class="label">乘客信息</div>
          <el-row>
            <el-col :span="18">
              <el-form-item label="下单人电话：" prop="passengerPhone">
                <el-input
                  v-model="form.passengerPhone"
                  maxlength="11"
                  placeholder="请输入来电手机号"
                  @input="inputPhone"
                  :style="{ width: headerText == '预留' ? '80%' : '100%' }"
                ></el-input>
                <span v-if="headerText == '预留'" style="margin-left: 8px;color: #e74848" @click="popTips">
                  <i
                    class="el-icon-warning"                    
                    style="color: #e74848"
                  ></i
                  >注意事项
                </span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="购票类型：" prop="realName">
                <el-radio v-model="form.realName" disabled :label="false"
                  >匿名</el-radio
                >
                <el-radio v-model="form.realName" disabled :label="true"
                  >实名</el-radio
                >
              </el-form-item>
            </el-col>
          </el-row>
          <div class="label">行程信息</div>
          <el-row>
            <el-col :span="10">
              <el-form-item label="出发地：" prop="startStation">
                <el-select
                  v-model="form.startStation"
                  filterable
                  clearable
                  disabled
                  @focus="selectStartStation"
                  @change="changeStation(0)"
                >
                  <el-option
                    v-for="item in startStationList"
                    :key="item.stationName"
                    :label="item.stationName"
                    :value="item.stationName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="到达地：" prop="endStation">
                <el-select
                  v-model="form.endStation"
                  filterable
                  clearable
                  disabled
                  @focus="selectEndStation"
                  @change="changeStation(1)"
                >
                  <el-option
                    v-for="item in endStationList"
                    :key="item.stationName"
                    :label="item.stationName"
                    :value="item.stationName"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-form-item label="出行日期：" prop="departureDate">
                <!-- :picker-options="pickerOptions" -->
                <el-date-picker
                  v-model="form.departureDate"
                  value-format="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  placeholder="请选择出行时间"
                  :clearable="false"
                  @change="changeTimeSelect"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label-width="10px">
                <el-radio-group
                  v-model="form.timeSelect"
                  @input="changedepartureDate"
                >
                  <el-radio :label="1">今天</el-radio>
                  <el-radio :label="2">明天</el-radio>
                  <el-radio :label="3">后天</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="10">
              <el-form-item label="班次时间：" prop="shiftIntercityId">
                <el-select
                  v-model="form.shiftIntercityId"
                  @focus="selectShift"
                  @change="confirmShift"
                >
                  <el-option
                    v-for="item in shiftList"
                    :key="item.shiftIntercityId"
                    :disabled="item.isDisabled"
                    :label="item.label"
                    :value="item.shiftIntercityId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="shiftType === 1">
              <el-form-item
                label="期望出行时间："
                prop="anticipationRidingTime"
              >
                <el-select width="80px" v-model="form.anticipationRidingTime">
                  <el-option
                    v-for="item in anticipationRidingTimeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <span v-if="form.shiftIntercityId" style="margin-left: 10px">
              此班次剩余携童票
              <span style="color: red; line-height: 36px">{{
                surplusCarryChildren
              }}</span>
              张
            </span>
          </el-row>
          <template v-if="form.realName">
            <el-row v-for="(item, index) in form.usedPassengerVOS" :key="index">
              <el-col :span="7">
                <el-form-item
                  :label="index == 0 ? '乘客：' : ''"
                  :prop="'usedPassengerVOS.' + index + '.userName'"
                  :rules="{
                    required: true,
                    message: '请输入乘客姓名',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="item.userName" placeholder="乘客姓名" />
                </el-form-item>
              </el-col>
              <el-col :span="7">
                <el-form-item
                  label-width="20px"
                  :prop="'usedPassengerVOS.' + index + '.idCardNo'"
                  :rules="{
                    required: true,
                    validator: (rule, value, callback) => {
                      checkIdentificationNumber(rule, value, callback)
                    },
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    v-model="item.idCardNo"
                    placeholder="乘客身份证号"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item
                  :prop="'usedPassengerVOS.' + index + '.segment'"
                  label-width="20px"
                  :rules="{
                    required: true,
                    message: '请选择乘客类型',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    v-model="item.segment"
                    @change="(e) => selectSegment(e, item)"
                  >
                    <el-option :value="1" label="成人" />
                    <el-option :value="0" label="儿童" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label-width="20px">
                  <el-checkbox
                    v-if="item.segment"
                    v-model="item.isCarryingChildren"
                    @change="clickChildren(index)"
                    >携童</el-checkbox
                  >
                  <i
                    v-if="form.usedPassengerVOS.length > 1"
                    class="iconfont its_gy_delete"
                    @click="deleteVisitor(index)"
                  ></i>
                </el-form-item>
              </el-col>
            </el-row>
            <div
              v-if="isFirstChange"
              style="color: red; margin-bottom: 8px; text-align: center"
            >
              携童数发生改变请重新选择
            </div>
            <div v-if="!payStatus" class="add" @click="addNew">
              <i class="iconfont its_tianjia" style="margin-right: 10px"></i>
              添加新乘客
            </div>
          </template>
          <el-row v-else>
            <el-col :span="8">
              <el-form-item label="乘客人数：" prop="passengerCount">
                <el-input-number
                  v-model="form.passengerCount"
                  size="small"
                  :min="1"
                  :max="computedPassengerCountMax"
                  @change="selectValPer"
                ></el-input-number>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="携童数量：" prop="carryingChildrenNum">
                <el-input-number
                  v-model="form.carryingChildrenNum"
                  size="small"
                  :min="0"
                  :max="
                    form.passengerCount >= surplusCarryChildren
                      ? surplusCarryChildren
                      : form.passengerCount
                  "
                ></el-input-number>
              </el-form-item>
            </el-col>
            <div
              v-if="isFirstChange"
              style="color: red; line-height: 36px; margin-left: 10px"
            >
              携童数发生改变请重新选择
            </div>
          </el-row>
          <el-row>
            <el-col :span="19">
              <el-form-item
                label="上车点："
                :prop="addressRequired ? 'startAddress' : ''"
              >
                <div class="address">
                  <div class="editbox" @click="selectAddress(0)">
                    <el-input
                      v-model="form.startAddress"
                      placeholder="请选择上车点"
                    ></el-input>
                    <!-- <el-select
                      v-if="!upStationList.length"
                      v-model="form.startAddress"
                      placeholder="请输入上车点"
                      filterable
                      remote
                      reserve-keyword
                      :remote-method="(e) => remoteAddress(e, 0)"
                      style="width: 100%"
                      @focus="focusAddress"
                      @change="selectAreaPoint"
                    >
                      <el-option
                        v-for="(item, index) in addressList"
                        :key="index"
                        :label="item.address"
                        :value="item.address"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-else
                      v-model="form.startAddress"
                      placeholder="请选择上车点"
                      style="width: 100%"
                      @change="(e) => selectFixedStation(e, 0)"
                    >
                      <el-option
                        v-for="(item, index) in upStationList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select> -->
                    <i
                      v-if="payStatus === 0 && !isAwaitPay"
                      class="iconfont its_gy_map"
                    ></i>
                  </div>
                  <ul v-if="payStatus === 0 && !isAwaitPay">
                    <li
                      v-for="(item, index) in upCatchList"
                      :key="index"
                      @click="selectCatchAddress(item, 0)"
                    >
                      {{ item.address }}
                    </li>
                  </ul>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="19">
              <el-form-item
                label="下车点："
                :prop="addressRequired ? 'endAddress' : ''"
              >
                <div class="address">
                  <div class="editbox" @click="selectAddress(1)">
                    <!-- <el-select
                      v-if="!downStationList.length"
                      v-model="form.endAddress"
                      placeholder="请输入下车点"
                      filterable
                      remote
                      reserve-keyword
                      :remote-method="(e) => remoteAddress(e, 1)"
                      style="width: 100%"
                      @focus="focusAddress"
                      @change="selectAreaPoint"
                    >
                      <el-option
                        v-for="(item, index) in addressList"
                        :key="index"
                        :label="item.address"
                        :value="item.address"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-else
                      v-model="form.endAddress"
                      placeholder="请选择下车点"
                      style="width: 100%"
                      @change="(e) => selectFixedStation(e, 1)"
                    >
                      <el-option
                        v-for="(item, index) in downStationList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select> -->
                    <!-- 待支付1 -->
                    <el-input
                      v-model="form.endAddress"
                      placeholder="请选择上车点"
                    ></el-input>
                    <i
                      v-if="payStatus === 0 && !isAwaitPay"
                      class="iconfont its_gy_map"
                    ></i>
                  </div>
                  <ul v-if="payStatus === 0 && !isAwaitPay">
                    <li
                      v-for="(item, index) in downCatchList"
                      :key="index"
                      @click="selectCatchAddress(item, 1)"
                    >
                      {{ item.address }}
                    </li>
                  </ul>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="19">
              <el-form-item label="备注：">
                <el-input
                  v-model="form.remarks"
                  placeholder="请输入备注"
                  type="textarea"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="label" v-if="computedDriverOrderId">补票派车</div>
          <el-row v-if="computedDriverOrderId">
            <el-col :span="24">
              <el-form-item label="" prop="radioValue">
                <el-radio-group
                  v-if="radioArray.length"
                  v-model="form.radioValue"
                  @change="handleRadioChanges"
                >
                  <template>
                    <el-row style="padding-top: 20px">
                      <el-radio :key="0" :label="0">不派车 </el-radio>
                    </el-row>
                  </template>
                  <template>
                    <div
                      v-for="opt in radioArray"
                      :key="opt.id"
                      class="radio-row"
                    >
                      <el-radio :label="opt.id" :disabled="opt.disabled">
                        <template>
                          <div class="name">{{ opt.driverName }}</div>
                          <div class="phone">{{ opt.driverPhone }}</div>
                          <div class="cph">{{ opt.licensePlateNumber }}</div>
                          <div class="sur">
                            余座<span>{{
                              opt.surplusCarryPassengerNumber
                            }}</span>
                          </div>
                          <div
                            :class="[
                              'card-status',
                              computedClass(opt.orderStatus),
                            ]"
                          >
                            {{ computedStatus(opt.orderStatus) }}
                          </div>
                        </template>
                      </el-radio>
                    </div>
                  </template>
                </el-radio-group>
                <template v-else>
                  <div class="tips">
                    <i class="el-icon-warning"></i
                    >该班次未指派车辆和驾驶员，下单支付后请及时指派车辆和驾驶员。
                  </div>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div
        class="pay-money"
        :class="{
          'pay-money1': payStatus == 1,
          'pay-money2': payStatus == 2,
          'pay-money3': payStatus == 3,
        }"
      >
        <div class="total-detail">
          <div class="item">票价：{{ computedTicktPrice }} 元/张</div>
          <div class="item">服务费：{{ form.servicePricesPer }} 元/张</div>
          <div class="item">乘坐人数：{{ form.passengerCount }} 人</div>
        </div>
        <div class="total-price">
          应付金额：
          <div class="price">{{ form.totalPrices }}</div>
        </div>
        <div
          v-if="payStatus"
          class="pay-flag"
          :class="{
            'pay-flag1': payStatus == 1,
            'pay-flag2': payStatus == 2,
            'pay-flag3': payStatus == 3,
          }"
        ></div>
        <div v-if="payStatus" class="pay-text">
          {{ payStatus == 1 ? '待支付' : payStatus == 2 ? '已取消' : '已完成' }}
        </div>
      </div>
      <p v-if="payStatus == 1" class="cancel-time">
        <i class="el-icon-info"></i>
        <span>请在</span>
        <span class="time">{{ cancelTime }}</span>
        <span>内完成支付，逾期将自动取消订单</span>
      </p>
      <div v-if="!payStatus" class="footer-self">
        <el-button type="primary" :disabled="loading" @click="placeOrder"
          >立即下单</el-button
        >
        <el-button type="default" @click="closeFun">关闭</el-button>
      </div>
      <div v-else-if="payStatus == 1" class="footer-self">
        <el-button type="primary" @click="payOrder">立即支付</el-button>
        <el-button type="default" @click="cancelOrder">取消订单</el-button>
        <el-button type="default" @click="closeFun">关闭</el-button>
      </div>
      <div v-else class="footer-self">
        <el-button type="default" @click="closeFun">关闭</el-button>
      </div>
    </div>
    <el-dialog
      v-if="payCodeShow"
      :visible.sync="payCodeShow"
      title="下单支付"
      width="700px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="payCodeClose"
    >
      <div v-if="!result" class="scanning">
        <header v-if="payStatus == 1 && !coverShow">
          <span>微信支付</span>
          <span>请在</span>
          <span>{{ scanningTime }}</span>
          <span>内扫码支付</span>
        </header>
        <div class="middle">
          <div class="qrcode">
            <div class="money">
              <span>支付金额：</span>
              <span>{{ form.totalPrices }}</span>
            </div>
            <!--二维码-->
            <div id="image" ref="qrcode"></div>
            <div class="transprant"></div>
            <div v-if="coverShow" class="cover">
              <button @click="reloadQR">二维码失效，点击重新获取二维码</button>
            </div>
            <div class="explain">
              <i class="iconfont its_gouxuan"></i>
              <span>请使用微信扫描二维码支付</span>
            </div>
          </div>
          <div class="weixin">
            <img src="~@/assets/images/home/scanning.png" alt />
          </div>
        </div>
      </div>
      <div v-else class="result">
        <div v-if="result == 1" class="type">
          <img src="~@/assets/images/home/success.png" alt />
          <span>支付成功</span>
          <p>您已支付成功，请关闭窗口查看订单详情。</p>
        </div>
        <div v-else class="type">
          <img src="~@/assets/images/home/fail.png" alt />
          <span style="color: red">支付失败</span>
          <p>支付失败，请关闭窗口重新提交。</p>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      v-if="showMap"
      :title="addressType ? '选择下车点' : '选择上车点'"
      :visible.sync="showMap"
      width="1000px"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      @close="cancelBtn"
    >
      <div class="map">
        <div class="searchbox">
          <header>
            <input v-model="searchInput" type="text" placeholder="搜索地址" />
            <el-button type="primary" size="small">搜索</el-button>
          </header>
          <div class="pointlist">
            <p
              v-for="(item, index) in addressList"
              :key="index"
              @click="searchClick(item, addressType)"
            >
              {{ item.address }}
            </p>
          </div>
        </div>
        <div class="select">
          <!-- <div v-if="areaMode">
            <header>请选择地点</header>
            <div class="point">
              <div
                v-for="(item, index) in pointList"
                :key="index"
                class="item"
                :class="{ item1: currentIndex === index }"
                @click="clickPoint(item, index)"
              >
                <span>{{ item.name }}</span>
                <span class="detial" :title="item.address">
                  {{ item.detialAddress.split("|")[1] }}
                </span>
              </div>
            </div>
          </div> -->
          <div v-if="!searchInput">
            <header>请选择地址</header>
            <div class="point">
              <div
                v-for="(item, index) in allStationList"
                :key="index"
                class="item"
                :class="{ item1: currentIndex === index }"
                @click="clickArea(item, index)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div id="container" />
      </div>
      <span v-cloak slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="cancelBtn"
          >取消</el-button
        >
        <el-button type="primary" size="small" @click="confirmAddress"
          >选择</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="tipsdialogVisible"
      width="700px"
      :append-to-body="true"
      top="30vh"
    >
      <div class="safe-tips">
        <div class="title">
          为了满足监管要求，即日起（3月18日起），使用预留结合快客码付款的线路注意：
        </div>
        <div>1、预留票的预留手机号码必须是乘客号码</div>
        <div>
          2、乘客扫码的微信必须是预留票手机注册，否则使用快客码时无法获取到预留订单并支付
        </div>
        <div>3、预留票订单初始手机号码不是乘客手机号码时，允许调度进行修改</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          size="small"
          type="primary"
          @click="tipsdialogVisible = false"
          >知道了</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { checkPhone } from "@/common/utils/index.js";
import moment from "moment";
import {
  placeOrderAPI,
  searchShiftTimeAPI,
  searchLineAPI,
  configureParamsAPI,
  queryAreaList,
  queryKkPassengerCacheList,
  searchPointAPI,
  saveOrUpdate,
  payOrderAPI,
  cancelOrderAPI,
  getPhoneOrderListAPI,
} from "@/api/lib/api";
import { getShiftDriverOrdersAPI } from "@/api/lib/remouldAPI.js";
import axios from "axios";
import QRCode from "qrcodejs2";
import cryptoJs from "crypto-js";
let timer = null;
export default {
  components: {},
  props: {
    headerText: {
      type: String,
      default: "",
    },
    createOrderArgument: {
      type: Object,
      default: () => { },
    },
    //是否是待支付
    isAwaitPay: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        orderPhone: null,
        passengerPhone: null,
        realName: false,
        startStation: null,
        endStation: null,
        departureDate: moment().format("YYYY-MM-DD"),
        timeSelect: 1,
        shiftIntercityId: null,
        passengerCount: 1,
        carryingChildrenNum: 0,
        usedPassengerVOS: [{ userName: "", idCardNo: "", segment: "" }],
        startAddress: null,
        startPointLongitude: null,
        startPointLatitude: null,
        startLineAreaId: null,
        startStationId: null,
        startStationType: null,
        endAddress: null,
        endPointLongitude: null,
        endPointLatitude: null,
        endStationId: null,
        endStationType: null,
        endLineAreaId: null,
        remarks: null,
        totalPrices: 0, //订单总价
        ticketPricesPer: 0, //每张票钱=车票单价+服务费+溢价
        autoSeatSelection: true,
        servicePricesPer: 0, //服务费
        anticipationRidingTime: "",
        orderStatus: null,
        radioValue: null,
      },
      price: 0, //原车票单价
      ticketPrice: 0, //最终车票单价
      rules: {
        passengerPhone: {
          required: true,
          validator: (rule, value, callback) => {
            checkPhone(rule, value, callback);
          },
          trigger: "blur",
        },
        startStation: {
          required: true,
          message: "请选择出发地",
          trigger: "blur",
        },
        endStation: {
          required: true,
          message: "请选择到达地",
          trigger: "blur",
        },
        departureDate: {
          required: true,
          message: "请选择出行时间",
          trigger: "blur",
        },
        shiftIntercityId: {
          required: true,
          message: "请选择班次时间",
          trigger: "blur",
        },
        anticipationRidingTime: {
          required: true,
          message: "请选择班次时间",
          trigger: "blur",
        },
        usedPassengerVOS: [{ userName: "", idCardNo: "", segment: "" }],
        startAddress: {
          required: true,
          message: "请输入上车点",
          trigger: "change",
        },
        endAddress: {
          required: true,
          message: "请输入下车点",
          trigger: "change",
        },
        radioValue: {
          required: true,
          validator: (rule, value, callback) => {
            if (this.radioArray.length) {
              if (value || value === 0) {
                callback();
              } else {
                callback(new Error("请选择补票派车"));
              }
            } else {
              callback();
            }
          },
          trigger: "blur",
        },
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      startStationList: [],
      endStationList: [],
      shiftList: [],
      surplusCarryChildren: 0, //班次剩余携童票数
      lineId: null, //选择的线路id
      upCatchList: [], //上车点缓存站点列表
      downCatchList: [], //下车点缓存站点列表
      copyUpCatchList: [], //上车点缓存站点列表
      copyDownCatchList: [], //下车点缓存站点列表
      cacheId: null, //缓存数据id
      upStationList: [], //上车点是固定点站点列表
      downStationList: [], //下车点是固定点站点列表
      upAreaList: [], //上车点是区域站点列表
      downAreaList: [], //下车点是区域站点列表
      showMap: false,
      addressType: null,
      areaMode: null,
      map: null,
      pickerPosition: null,
      positionLatLng: null,
      marker: null,
      areaPath: [],
      searchInput: null,
      addressList: [], //地图搜索出的地点列表
      pointList: [], //固定点列表
      areaList: [], //区域列表
      allStationList: [],//区域共存共同的点
      currentIndex: -1,
      searchAddress: null,
      selectStartPoint: {
        //选择的上车点
        name: null,
        address: null,
        detialAddress: null,
        lng: null,
        lat: null,
        areaType: null,
        stationId: null,
        id: null,
        floatingPrice: 0,
        price: 0,
      },
      selectEndPoint: {
        //选择的下车点
        name: null,
        address: null,
        detialAddress: null,
        lng: null,
        lat: null,
        areaType: null,
        stationId: null,
        id: null,
        floatingPrice: 0,
        price: 0,
      },
      upFloatingPrice: 0, //上车点溢价
      downFloatingPrice: 0, //下车点溢价
      showPrice: true,
      loading: false,
      anticipationRidingTimeList: [], //期望出行时间
      shiftType: null,
      currentShiftObj: {},
      addressRequired: true, //上下车点是否必填
      payStatus: 0, //0：未下单，1：待支付，2:已取消，3：已完成
      lockTime: 0,
      payTimer: null,
      cancelTime: "",
      payCodeShow: false,
      qrcode: "",
      scanningTime: null,
      coverShow: false,
      result: 0, //0：还未支付显示二维码，1：支付成功，2:支付失败
      payCodeTimer: null,
      outTradeNo: null,
      lineOrderPassengerMax: 0, //一单可购票人数
      isFirstChange: false, //标记第一次携童数改变不提示
      channelNO: "", //线路渠道号
      radioArray: [],
      tipsdialogVisible: false,
    };
  },
  watch: {
    searchInput: {
      handler (val) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          this.searchFun(val);
        }, 500);
      },
    },
  },
  created () {
    if (this.createOrderArgument) {
      //调度9.0.0 - 线路回显
      const {
        lineId,
        lineName,
        departureDate,
        shiftIntercityId,
        shiftType,
        orderId,
        startStation,
        endStation,
        driverOrderId,
      } = this.createOrderArgument;
      this.shiftType = shiftType;
      this.lineId = lineId;
      this.form.lineName = lineName;
      this.form.startStation = startStation;
      this.form.endStation = endStation;
      this.form.departureDate = departureDate;
      this.form.radioValue = driverOrderId;
      this.computedDaysText(departureDate);
      this.AssignEcho(shiftIntercityId, orderId); //班次数据回显 -待支付处理也在其中
    }
  },
  computed: {
    computedPassengerCountMax () {
      let computedNum = 0;
      if (this.lineOrderPassengerMax > this.currentShiftObj?.surplusTicket) {
        computedNum = this.currentShiftObj?.surplusTicket;
      } else {
        computedNum = this.lineOrderPassengerMax;
      }
      return computedNum;
    },
    //计算并处理精度
    computedTicktPrice () {
      let all =
        Math.round(
          (this.form.ticketPricesPer - this.form.servicePricesPer) * 100
        ) / 100;
      return all;
    },
    computedDriverOrderId () {
      /**
       * 只要班次结束时间小于当前时间的
       * 只有补票才有
       */
      const { endDepartureDateTime } = this.currentShiftObj;
      let endTemp = new Date(endDepartureDateTime).getTime();
      let nowTemp = new Date().getTime();
      return this.headerText === "订票" && endTemp <= nowTemp;
    },
  },
  methods: {
    popTips () {
      this.tipsdialogVisible = true;
    },
    queryShiftDriversOrder (shiftIntercityId) {
      getShiftDriverOrdersAPI({ shiftIntercityId }).then((res) => {
        if (res.code == "SUCCESS") {
          this.radioArray = res.data?.filter((item) => {
            item.disabled =
              item.surplusCarryPassengerNumber < this.form.passengerCount;
            return item;
          });
        }
      });
    },
    handleRadioChanges (value) {
      console.log("选中：", value);
    },
    inputPhone (val) {
      if (val.length == 11 || val == "") {
        this.queryCacheData();
      } else {
        this.upCatchList = [];
        this.downCatchList = [];
        this.copyDownCatchList = [];
        this.copyUpCatchList = [];
        this.cacheId = null;
      }
    },
    async AssignEcho (shiftIntercityId, orderId) {
      let result = await this.selectShift();
      this.form.shiftIntercityId = shiftIntercityId;
      let timeObj = result.find(
        (item) => item.shiftIntercityId == shiftIntercityId
      );
      this.currentShiftObj = { ...timeObj };
      if (timeObj) {
        this.confirmShift(shiftIntercityId, true); //初始化价格计算
      }
      if (timeObj?.shiftType === 1) {
        this.anticipationRidingTimeList = this.getHalfHourlyTimes(
          timeObj.startDepartureTime,
          timeObj.endDepartureTime
        );
      }
      //待支付-获取数据渲染前的规则
      this.$nextTick(() => {
        if (this.isAwaitPay) {
          this.payStatus = 1; //待支付
          this.form.shiftIntercityId = shiftIntercityId;
          configureParamsAPI({
            lineId: this.lineId,
            channelNO: this.channelNO,
          }).then((res) => {
            if (res.code == "SUCCESS") {
              this.lockTime = JSON.parse(
                res.data.ORDER_LOCK_TIME
              ).regulationValue;
              this.renderData(orderId);
            }
          });
        }
      });
    },
    getHalfHourlyTimes (startTime, endTime) {
      let y = new Date(this.form.departureDate).getFullYear();
      let m = new Date(this.form.departureDate).getMonth() + 1;
      let d = new Date(this.form.departureDate).getDate();
      let start = new Date(y + "/" + m + "/" + d + " " + startTime);
      let end = new Date(y + "/" + m + "/" + d + " " + endTime);
      let list = [];
      function formatTime (time) {
        //时间为个位数则补零
        return time < 10 ? "0" + time : time;
      }
      let len2 = (end.getTime() - start.getTime()) / (30 * 60 * 1000); //计算各子区间以半小时为间隔拆分后的数组长度
      for (let j = 0; j < len2; j++) {
        if (start.getTime() + 30 * 60 * 1000 <= end.getTime()) {
          //将各子区间日期按半小时递增转换为时间并存入list数组
          let ss = new Date(start.getTime() + 30 * 60 * 1000 * j);
          let flag = new Date(start.getTime() + 30 * 60 * 1000 * (j + 1)) > end;
          let ee = null;
          if (flag) {
            ee = end;
          } else {
            ee = new Date(start.getTime() + 30 * 60 * 1000 * (j + 1));
          }
          list.push({
            label: `${formatTime(ss.getHours())}:${formatTime(
              ss.getMinutes()
            )} - ${formatTime(ee.getHours())}:${formatTime(ee.getMinutes())}`,
            value: `${ss.getTime()}-${ee.getTime()}`,
          });
        } else {
          let label = `${formatTime(start.getHours())}:${formatTime(
            start.getMinutes()
          )} - ${formatTime(end.getHours())}:${formatTime(end.getMinutes())}`;
          list.push({
            label: label,
            value: `${start.getTime()}-${end.getTime()}`,
          });
        }
      }
      return list;
    },
    closeFun () {
      if (this.payTimer) clearInterval(this.payTimer);
      if (this.payCodeTimer) clearInterval(this.payCodeTimer);
      this.payCodeShow = false;
      this.$emit("closePopupFun", false);
    },
    selectStartStation () {
      this.form.shiftIntercityId = null;
      const data = {
        direction: 0,
        chosenStation: this.form.endStation || null,
      };
      searchLineAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.startStationList = res.data.list;
        }
      });
    },
    selectEndStation () {
      this.form.shiftIntercityId = null;
      const data = {
        direction: 1,
        chosenStation: this.form.startStation || null,
      };
      searchLineAPI(data).then((res) => {
        if (res.code == "SUCCESS") {
          this.endStationList = res.data.list;
        }
      });
    },
    //修改出发地到达地
    changeStation (type) {
      if (type == 0) {
        this.form.startAddress = null;
        this.form.endAddress = null;
      } else {
        this.form.startAddress = null;
        this.form.endAddress = null;
      }
      this.form.shiftIntercityId = null;
      this.upCatchList = [];
      this.downCatchList = [];
      this.copyDownCatchList = [];
      this.copyUpCatchList = [];
      this.cacheId = null;
      this.addressList = [];
    },
    //选择出行日期
    changeTimeSelect (val) {
      this.form.shiftIntercityId = null;
      this.addressList = [];
      this.computedDaysText(val);
    },
    computedDaysText (val) {
      const date1 = new Date(val).getTime();
      const date2 = new Date(moment().format("YYYY-MM-DD")).getTime();
      if (date1 - date2 == 0) {
        this.form.timeSelect = 1;
      } else if (date1 - date2 == 3600 * 1000 * 24) {
        this.form.timeSelect = 2;
      } else if (date1 - date2 == 3600 * 1000 * 48) {
        this.form.timeSelect = 3;
      } else {
        this.form.timeSelect = null;
      }
    },
    changedepartureDate (val) {
      if (val == 1) {
        this.form.departureDate = moment().format("YYYY-MM-DD");
      } else if (val == 2) {
        this.form.departureDate = moment().add(1, "day").format("YYYY-MM-DD");
      } else {
        this.form.departureDate = moment().add(2, "day").format("YYYY-MM-DD");
      }
      this.form.shiftIntercityId = null;
      this.addressList = [];
    },
    computedDepartureTime (item) {
      let timeShowValue = item.startDepartureTime;
      if (item.shiftType === 1) {
        timeShowValue = item.startDepartureTime + "-" + item.endDepartureTime;
      }
      return timeShowValue;
    },
    // 判断是否满足选班次时间的条件
    selectShift () {
      this.shiftList = [];
      return new Promise((resolved, reject) => {
        searchShiftTimeAPI({
          startStation: this.form.startStation,
          endStation: this.form.endStation,
          departureDate: this.form.departureDate,
          isSuspended: true,
          shiftBefore: 1,
          lineIds: this.lineId,
        }).then((res) => {
          if (res.code == "SUCCESS") {
            this.shiftList = res.data.filter((item) => {
              //9.0.0可以买之前时间的票、余票0的置灰
              let departureTime = this.computedDepartureTime(item);
              item.isDisabled = item.surplusTicket === 0;
              item.label = departureTime + " - 余票" + item.surplusTicket;
              return item;
            });
            resolved(this.shiftList);
          }
        });
      });
    },
    // 选择班次时间
    confirmShift (val, flag) {
      this.ticketPrice = 0;
      let pop = this.shiftList
        .filter((item) => item.shiftIntercityId == val)
        .pop();
      this.channelNO = pop.channelNO; //缓存线路渠道号
      this.currentShiftObj = { ...pop }; //当前选择的班次信息
      //切换了清空携童数据
      this.form.carryingChildrenNum = 0;
      if (!flag) {
        this.isFirstChange = true;
      }
      this.form.realName = !!pop.isRealName;
      if (
        this.form.realName &&
        this.form.usedPassengerVOS &&
        this.form.usedPassengerVOS.length
      ) {
        //实名清空携童数
        this.form.usedPassengerVOS.map((item) => {
          item && item.isCarryingChildren && (item.isCarryingChildren = false);
        });
      }
      this.shiftType = pop.shiftType;
      this.surplusCarryChildren = pop.surplusCarryingChildrenNum;
      this.ticketPrice = pop?.discountTicketPrice || pop?.ticketPrice || 0;
      this.price = this.ticketPrice;
      const arr = [
        ...pop.lineAreaTimeTables,
        ...pop.lineAreaGetOffTables,
        ...pop.lineMidwayStations,
      ];
      //溢价+途经点 上线车点必填，否则选填
      this.addressRequired = arr.some(
        (item) => item.floatingPrice > 0 || item.areaType == 2
      );
      this.form.anticipationRidingTime = "";
      if (pop.shiftType === 1) {
        this.anticipationRidingTimeList = this.getHalfHourlyTimes(
          pop.startDepartureTime,
          pop.endDepartureTime
        );
      } else {
        this.anticipationRidingTimeList = [];
      }
      /**
       * 如果是补票需要调接口---需要跟随班次变更
       */
      if (this.computedDriverOrderId) {
        this.queryShiftDriversOrder(pop.shiftIntercityId);
      }
      //获取购票规则
      configureParamsAPI({ lineId: pop.lineId, channelNO: pop.channelNO }).then(
        (res) => {
          if (res.code == "SUCCESS") {
            this.form.servicePricesPer = JSON.parse(
              res.data.SERVICE_FEE
            ).regulationValue;
            this.lineOrderPassengerMax = JSON.parse(
              res.data.ORDER_BUY_TICKET_AMOUNT
            ).regulationValue; //获取订单最大购买数
            this.form.ticketPricesPer = this.form.servicePricesPer + this.price;
            if (!arr.some((e) => e.floatingPrice)) {
              this.showPrice = true;
              this.upFloatingPrice = 0;
              this.downFloatingPrice = 0;
              this.computeTotal();
            } else {
              this.showPrice = false;
            }
          }
        }
      );
      //获取上下车点数据
      const dataObj = {
        lineId: this.lineId,
        shiftIntercityId: this.form.shiftIntercityId,
      };
      queryAreaList({ ...dataObj, areaType: 0 }).then((res) => {
        if (res.code == "SUCCESS") {
          if (res.data.every((e) => e.areaMode == 0)) {
            //区域
            this.upStationList = [];
            this.upAreaList = res.data;
          } else {
            this.upStationList = res.data.map((item) => {
              let arr = JSON.parse(item.areaContent);
              let obj = arr[0];
              return {
                name: item.name,
                address: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              };
            });
          }
        }
      });
      queryAreaList({ ...dataObj, areaType: 1 }).then((res) => {
        if (res.code == "SUCCESS") {
          if (res.data.every((e) => e.areaMode == 0)) {
            this.downStationList = [];
            this.downAreaList = res.data;
          } else {
            this.downStationList = res.data.map((item) => {
              let arr = JSON.parse(item.areaContent);
              let obj = arr[0];
              return {
                name: item.name,
                address: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              };
            });
          }
        }
      });
    },
    //查询缓存数据
    queryCacheData () {
      const data = {
        type: 3,
        code: this.form.passengerPhone + "-" + this.lineId,
      };
      queryKkPassengerCacheList(data).then((res) => {
        if (res.code == "SUCCESS" && res.data.length > 0) {
          const data = JSON.parse(res.data[0].cacheDate);
          this.upCatchList = data.upCatch.filter((e) => e.address);
          this.downCatchList = data.downCatch.filter((e) => e.address);
          this.copyUpCatchList = data.upCatch.filter((e) => e.address);
          this.copyDownCatchList = data.downCatch.filter((e) => e.address);
          this.cacheId = res.data[0].id;
        }
      });
    },
    //验证身份证号
    checkIdentificationNumber (rule, value, callback) {
      if (value && value !== "") {
        const regEn =
          /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        const arr = this.form.usedPassengerVOS.filter(
          (e) => e.idCardNo == value
        );
        const obj = this.form.usedPassengerVOS.find((e) => e.idCardNo == value);
        let text = "";
        if (obj && obj.idCardNo2) {
          text = obj.idCardNo2;
        } else {
          text = value;
        }
        if (!regEn.test(text)) {
          callback(new Error("请输入合法的身份证号码"));
        } else if (arr.length >= 2) {
          callback(new Error("乘客身份证号重复，请重新输入"));
        } else {
          callback();
        }
      } else {
        callback("请输入身份证号码");
      }
    },
    // des加密
    encryptDes (message, key = "inheritech_key") {
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
      const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
      return encrypted.toString(); // 返回hex格式密文，如需返回base64格式：encrypted.toString()
    },
    // des解密
    decryptDes (message, key = "inheritech_key") {
      const keyHex = cryptoJs.enc.Utf8.parse(key);
      const decrypted = cryptoJs.DES.decrypt(
        message, // 若message是base64格式，则无需转16进制hex，直接传入message即可
        keyHex,
        {
          mode: cryptoJs.mode.ECB,
          padding: cryptoJs.pad.Pkcs7,
        }
      );
      return decrypted.toString(cryptoJs.enc.Utf8);
    },
    selectSegment (val, item) {
      if (!val && item.isCarryingChildren) {
        item.isCarryingChildren = false;
      }
    },
    clickChildren (index) {
      this.isFirstChange = false;
      let total = 0;
      this.form.usedPassengerVOS.map((e) => {
        e.isCarryingChildren && (total += 1);
      });
      if (total > this.surplusCarryChildren) {
        this.$alert(`携童票超出本班次剩余携童数！`, "提示", {
          confirmButtonText: "确定",
          center: true,
          callback: () => {
            this.form.usedPassengerVOS[index].isCarryingChildren = false;
          },
        });
      }
    },
    // 删除乘客
    deleteVisitor (index) {
      this.form.usedPassengerVOS.splice(index, 1);
      this.form.passengerCount = this.form.usedPassengerVOS.length;
      this.computeTotal();
    },
    // 添加新乘客
    addNew () {
      if (this.form.passengerCount >= this.computedPassengerCountMax) {
        return;
      }
      let obj = {
        userName: "",
        idCardNo: "",
        segment: "",
      };
      this.form.usedPassengerVOS.push(obj);
      this.form.passengerCount = this.form.usedPassengerVOS.length;
      this.computeTotal();
    },
    // 选择乘客人数
    selectValPer (cur, old) {
      if (cur < old && old == this.form.carryingChildrenNum) {
        this.$message.warning("若要继续减少乘客人数，请先减少携童数");
        this.$nextTick(() => {
          this.form.passengerCount = this.form.carryingChildrenNum;
        });
      }
      this.computeTotal();
      //乘客数改变，补票待派单需要更新禁用状态,情况选中状态
      if (this.computedDriverOrderId) {
        this.form.radioValue = null;
        this.radioArray = this.radioArray.filter((item) => {
          item.disabled =
            item.surplusCarryPassengerNumber < this.form.passengerCount;
          return item;
        });
      }
    },
    //缓存上车点
    catchUpAddress (obj) {
      if (this.copyUpCatchList.length < 3) {
        this.copyUpCatchList.push({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      } else {
        this.copyUpCatchList.pop();
        this.copyUpCatchList.push({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      }
    },
    //缓存下车点
    catchDownAddress (obj) {
      if (this.copyDownCatchList.length < 3) {
        this.copyDownCatchList.push({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      } else {
        this.copyDownCatchList.pop();
        this.copyDownCatchList.unshift({
          name: obj.name,
          address: obj.address,
          detialAddress: obj.detialAddress,
          lng: obj.lng,
          lat: obj.lat,
          stationId: obj.stationId,
          areaType: obj.areaType,
          id: obj.id,
          floatingPrice: obj.floatingPrice,
          price: obj.price,
        });
      }
    },
    selectFixedStation (val, type) {
      this.addressType = type;
      if (type === 0) {
        //上车点
        const obj = this.upStationList.find((i) => i.id == val);
        this.selectPoint(obj);
        this.pasteStartAddress();
        if (!this.showPrice) {
          this.form.endAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      } else {
        const obj = this.downStationList.find((i) => i.id == val);
        this.selectPoint(obj);
        this.pasteEndAddress();
        if (!this.showPrice) {
          this.form.startAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      }
    },
    // 地点选择
    selectAddress (val) {
      this.addressType = val;
      this.addressList = [];
      if (this.form.shiftIntercityId) {
        this.showMap = true;
        this.$nextTick(() => {
          this.loadmap(val);
        });
      } else {
        this.$message.warning("请先选择班次时间");
      }
    },
    focusAddress () {
      if (!this.form.shiftIntercityId) {
        this.$message.warning("请选择班次时间");
      } else {
        this.addressList = [];
      }
    },
    remoteAddress (val, type) {
      this.addressType = type;
      this.addressList = [];
      if (val !== "") {
        setTimeout(() => {
          const obj = {
            searchKey: val,
            lineId: this.lineId,
            areaType: this.addressType,
          };
          searchPointAPI(obj).then((res) => {
            if (res.code === "SUCCESS") {
              this.addressList = res.data;
            }
          });
        }, 200);
      } else {
        this.addressList = [];
      }
    },
    selectAreaPoint (val) {
      console.log("选择上下车点：", val);
      const obj = this.addressList.find((i) => i.address == val);
      const data = {
        lng: obj.wgLon,
        lat: obj.wgLat,
      };
      new Promise((resolve, reject) => {
        resolve(this.regeoFun(data));
        reject("error message");
      }).then((data) => {
        if (this.addressType === 0) {
          const item = this.upAreaList.find((e) => e.id == obj.lineAreaId);
          this.selectStartPoint = {
            name: item.name,
            address: obj.address,
            detialAddress: obj.address + "|" + data.split("|")[1],
            lng: obj.wgLon,
            lat: obj.wgLat,
            areaType: item.areaType,
            stationId: item.stationId,
            id: item.id,
            floatingPrice: item.floatingPrice,
            price: item.price,
          };
          this.form.startPointLatitude = obj.wgLat;
          this.form.startPointLongitude = obj.wgLon;
          this.form.startAddress = obj.address + "|" + data.split("|")[1];
          this.form.startStationId = item.stationId;
          this.form.startStationName = item.areaType == 2 ? item.name : null;
          this.form.startStationType = item.areaType;
          this.form.startLineAreaId = item.id;
          this.upFloatingPrice = item.floatingPrice;
          if (!this.showPrice) {
            this.form.endAddress && this.computeTotal();
          } else {
            this.computeTotal();
          }
        } else {
          const item = this.downAreaList.find((e) => e.id == obj.lineAreaId);
          this.selectEndPoint = {
            name: item.name,
            address: obj.address,
            detialAddress: obj.address + "|" + data.split("|")[1],
            lng: obj.wgLon,
            lat: obj.wgLat,
            areaType: item.areaType,
            stationId: item.stationId,
            id: item.id,
            floatingPrice: item.floatingPrice,
            price: item.price,
          };
          this.form.endPointLatitude = obj.wgLat;
          this.form.endPointLongitude = obj.wgLon;
          this.form.endAddress = obj.address + "|" + data.split("|")[1];
          this.form.endStationId = item.stationId;
          this.form.endStationType = item.areaType;
          this.downFloatingPrice = item.floatingPrice;
          if (item.areaType == 2) {
            this.form.endStationName = item.name;
            this.ticketPrice = item.price;
            this.form.endLineAreaId = null;
          } else {
            this.form.endStationName = null;
            this.form.endLineAreaId = item.id;
            this.ticketPrice = this.price;
          }
          if (!this.showPrice) {
            this.form.startAddress && this.computeTotal();
          } else {
            this.computeTotal();
          }
        }
      });
    },
    //实例化地图
    loadmap (type) {
      this.map = new AMap.Map("container", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      this.marker = null;
      const dataObj = {
        lineId: this.lineId,
        areaType: type,//上下车点类型
        shiftIntercityId: this.form.shiftIntercityId,
      };
      queryAreaList(dataObj).then((res) => {
        if (res.code == "SUCCESS") {
          const data = res.data;
          let singleStation = [];
          this.allStationList = data;//区域共存集合
          data.forEach((item) => {
            let arr = JSON.parse(item.areaContent);
            if (item.areaMode == 0) {
              //区域
              singleStation.push(item);
              // this.areaList = data;
              // this.areaMode = 0;
              let path = [];
              arr.forEach((e) => {
                path.push([e.lng, e.lat, item.id]);
                this.areaPath.push(path);
              });
              //初始化polygon
              var polygon = new AMap.Polygon({
                path,
                strokeColor: "#FF33FF",
                strokeWeight: 6,
                strokeOpacity: 0.2,
                fillOpacity: 0.4,
                fillColor: "#1791fc",
                zIndex: 50,
              });
              this.map.add(polygon);
              // this.positionPicker(dataObj.areaType, item);
            } else {
              // this.areaMode = 1;
              let obj = arr[0];
              let flag = new AMap.Marker({
                position: [obj.lng, obj.lat],
              });
              let markerTxt = new AMap.Text({
                text: item.name + "|" + item.detialAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: [obj.lng, obj.lat],
              });
              markerTxt.setMap(this.map);
              this.map.add(flag);
              // this.pointList.push({
              //   name: item.name,
              //   address: item.name,
              //   detialAddress: item.name + "|" + item.detialAddress,
              //   lng: obj.lng,
              //   lat: obj.lat,
              //   areaType: item.areaType,
              //   id: item.id,
              //   stationId: item.stationId,
              //   floatingPrice: item.floatingPrice,
              //   price: item.price,
              // });
              singleStation.push({
                name: item.name,
                address: item.name,
                detialAddress: item.name + "|" + item.detialAddress,
                lng: obj.lng,
                lat: obj.lat,
                areaType: item.areaType,
                id: item.id,
                stationId: item.stationId,
                floatingPrice: item.floatingPrice,
                price: item.price,
              });
            }
          });
          this.allStationList = [...singleStation];
          let position = null;
          if (type == 0) {
            position = this.selectStartPoint;
          } else {
            position = this.selectEndPoint;
          }
          if (position.lng && position.lat) {
            // if (this.areaMode) {
            //   this.currentIndex = this.pointList.findIndex(
            //     (i) => i.id == position.id
            //   );
            // } else {
            //   this.currentIndex = this.areaList.findIndex(
            //     (i) => i.id == position.id
            //   );
            // }
            this.currentIndex = this.allStationList.findIndex(
              (i) => i.id == position.id
            );
            //回显
            const stationItem = this.allStationList.filter(item => item.id == position.id)[0];
            if (this.areaMode == 0) {
              this.positionPicker(type, stationItem);
            } else {
              if (this.pickerPosition) {
                this.pickerPosition.stop();
                this.pickerPosition = null;
              }
            }
            this.positionLatLng = [position.lng, position.lat];
            this.map.setCenter([position.lng, position.lat]);
          } else {
            this.map.setFitView();
          }
        }
      });
    },
    positionPicker (type, item) {
      let vm = this;
      this.map.on("drag", function () {
        vm.marker && vm.marker.hide();
      });
      this.map.on("dragend", function () {
        vm.marker && vm.marker.show();
      });
      const arr = JSON.parse(item.areaContent).map((i) => [i.lng, i.lat]);
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          iconStyle: {
            url: require("@/assets/images/home/from_icon2.png"),
            size: [30, 39],
            ancher: [15, 30], //锚点的位置，即被size缩放之后，图片的什么位置作为选中的位置
          },
          zIndex: 666,
        });
        vm.pickerPosition.on("success", function (positionResult) {
          const pos = positionResult.position;
          vm.positionLatLng = [pos.lng, pos.lat];
          new Promise((resolve, reject) => {
            //成功的时候调用resolve
            resolve(vm.regeoFun(pos));
            //失败的时候调用reject
            reject("error message");
          }).then((data) => {
            if (type == 0) {
              vm.selectStartPoint.lat = pos.lat;
              vm.selectStartPoint.lng = pos.lng;
              //处理成功后的逻辑
              vm.selectStartPoint.detialAddress = data;
              vm.searchAddress = data;
              vm.selectStartPoint.address = data.split("|")[0];
              vm.selectStartPoint.name = item.name;
              const inRing = AMap.GeometryUtil.isPointInRing(
                [pos.lng, pos.lat],
                arr
              );
              if (inRing) {
                vm.selectStartPoint.stationId = item.stationId;
                vm.selectStartPoint.areaType = item.areaType;
                vm.selectStartPoint.id = item.id;
                vm.selectStartPoint.floatingPrice = item.floatingPrice;
                vm.selectStartPoint.price = item.price;
              }
            } else {
              vm.selectEndPoint.lat = pos.lat;
              vm.selectEndPoint.lng = pos.lng;
              vm.selectEndPoint.name = item.name;
              vm.selectEndPoint.detialAddress = data;
              vm.selectEndPoint.address = data.split("|")[0];
              vm.searchAddress = data;
              const inRing = AMap.GeometryUtil.isPointInRing(
                [pos.lng, pos.lat],
                arr
              );
              if (inRing) {
                vm.selectEndPoint.stationId = item.stationId;
                vm.selectEndPoint.areaType = item.areaType;
                vm.selectEndPoint.floatingPrice = item.floatingPrice;
                vm.selectEndPoint.id = item.id;
                vm.selectEndPoint.price = item.price;
              }
            }
            if (!vm.marker) {
              vm.marker = new AMap.Text({
                text: vm.searchAddress,
                zIndex: 777,
                anchor: "center",
                offset: new AMap.Pixel(0, -60),
                style: {
                  "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                  padding: "14px",
                },
                position: vm.positionLatLng,
              });
              vm.marker.setMap(vm.map);
            } else {
              vm.marker.setPosition(vm.positionLatLng);
              vm.marker.setText(vm.searchAddress);
            }
          });
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
    async regeoFun (position) {
      const res = await axios
        .get(
          `https://restapi.amap.com/v3/geocode/regeo?output=json&location=${position.lng},${position.lat}&key=d85b28ec7a166619a217cfe7cc0d700b&radius=1000&extensions=all`
        )
        .then((res) => {
          const addressComponent = res.data.regeocode.addressComponent;
          const abbreviation =
            res.data.regeocode.aois[0]?.name || addressComponent.district; //简称
          const detailedAddress =
            addressComponent.province +
            addressComponent.city +
            addressComponent.district +
            addressComponent.streetNumber.street +
            addressComponent.streetNumber.number; //详细地址
          return abbreviation + "|" + detailedAddress;
        });
      return res;
    },
    selectCatchAddress (item, type) {
      //已经下单了就不可操作性了
      if (this.payStatus > 0) return;
      if (type == 0) {
        this.selectStartPoint = item;
        this.form.startAddress = item.detialAddress;
        this.form.startPointLongitude = item.lng;
        this.form.startPointLatitude = item.lat;
        this.form.startStationId = item.stationId;
        this.form.startStationType = item.areaType;
        this.form.startLineAreaId = item.id;
        this.upFloatingPrice = item.floatingPrice;
        if (!this.showPrice) {
          this.form.endAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      } else {
        this.selectEndPoint = item;
        this.form.endAddress = item.detialAddress;
        this.form.endPointLongitude = item.lng;
        this.form.endPointLatitude = item.lat;
        this.form.endStationId = item.stationId;
        this.form.endStationType = item.areaType;
        this.downFloatingPrice = item.floatingPrice;
        if (item.areaType == 2) {
          this.form.endStationName = item.name;
          this.form.endLineAreaId = null;
          this.ticketPrice = item.price;
        } else {
          this.form.endStationName = null;
          this.form.endLineAreaId = item.id;
          this.ticketPrice = this.price;
        }
        if (!this.showPrice) {
          this.form.startAddress && this.computeTotal();
        } else {
          this.computeTotal();
        }
      }
    },
    // 点击搜索的地址--只能搜索出来区域
    searchClick (item) {
      this.currentIndex = this.allStationList.findIndex(
        (i) => i.id == item.lineAreaId
      );
      const stationItem = this.allStationList.filter(el => el.id == item.lineAreaId)[0];
      this.positionPicker(item.areaType, stationItem);
      this.positionLatLng = [item.wgLon, item.wgLat];
      this.map.setZoom(16);
      this.map.setCenter(this.positionLatLng);
      this.addressList = [];
      this.searchInput = null;
      this.searchAddress = item.address;
    },
    // 输入地名搜索地址
    searchFun (val) {
      if (val) {
        const obj = {
          searchKey: val,
          lineId: this.lineId,
          areaType: this.addressType,
        };
        searchPointAPI(obj).then((res) => {
          this.addressList = res.data;
        });
      } else {
        this.addressList = [];
      }
    },
    // 地图选择固定点
    clickPoint (item, index) {
      // this.currentIndex = index;
      this.positionLatLng = [item.lng, item.lat];
      this.map.setCenter(this.positionLatLng);
      this.selectPoint(item);
    },
    clickArea (item, index) {
      this.areaMode = item.areaMode;
      this.currentIndex = index;
      if (item.areaMode == 0) {
        //区域
        this.positionPicker(item.areaType, item);
        let arr = JSON.parse(item.areaContent);
        let path = [];
        arr.forEach((e) => {
          path.push([e.lng, e.lat, item.id]);
        });
        var polygon = new AMap.Polygon({
          path,
          strokeColor: "#FF33FF",
          strokeWeight: 6,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          fillColor: "#1791fc",
          zIndex: 50,
        });
        this.map.setFitView([polygon]);
      } else {
        //固定点取消选址拖动
        if (this.pickerPosition) {
          this.pickerPosition.stop();
          this.pickerPosition = null;
        }
        //固定点逻辑
        this.clickPoint(item, index);
      }

    },
    selectPoint (item) {
      if (this.addressType == 0) {
        this.selectStartPoint = { ...item };
      } else {
        this.selectEndPoint = { ...item };
      }
    },
    //赋值上车点
    pasteStartAddress () {
      this.form.startPointLatitude = this.selectStartPoint.lat;
      this.form.startPointLongitude = this.selectStartPoint.lng;
      this.form.startAddress = this.selectStartPoint.detialAddress;
      this.form.startStationId = this.selectStartPoint.stationId;
      if (this.selectStartPoint.areaType == 2) {
        this.form.startStationName = this.selectStartPoint.name;
      } else {
        this.form.startStationName = null;
      }
      this.form.startStationType = this.selectStartPoint.areaType;
      this.form.startLineAreaId = this.selectStartPoint.id;
      this.upFloatingPrice = this.selectStartPoint.floatingPrice;
    },
    //赋值下车点
    pasteEndAddress () {
      this.form.endPointLatitude = this.selectEndPoint.lat;
      this.form.endPointLongitude = this.selectEndPoint.lng;
      this.form.endAddress = this.selectEndPoint.detialAddress;
      this.form.endStationId = this.selectEndPoint.stationId;
      this.form.endStationType = this.selectEndPoint.areaType;
      if (this.selectEndPoint.areaType == 2) {
        this.form.endLineAreaId = null;
        this.form.endStationName = this.selectEndPoint.name;
        this.ticketPrice = this.selectEndPoint.price;
      } else {
        this.form.endLineAreaId = this.selectEndPoint.id;
        this.form.endStationName = null;
        this.ticketPrice = this.price;
      }
      this.downFloatingPrice = this.selectEndPoint.floatingPrice;
    },
    // 确认上车点下车点
    confirmAddress () {
      if (this.areaMode == 0) {
        let inRing = this.areaPath.findIndex((res) =>
          AMap.GeometryUtil.isPointInRing(this.positionLatLng, res)
        );
        if (inRing > -1) {
          if (this.addressType === 0) {
            this.pasteStartAddress();
            if (!this.showPrice) {
              this.form.endAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          } else {
            this.pasteEndAddress();
            if (!this.showPrice) {
              this.form.startAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          }
          this.cancelBtn();
        } else {
          this.marker && this.marker.hide();
          this.$message.warning("该地点不在可选区域内！");
        }
      } else {
        if (this.positionLatLng) {
          if (this.addressType === 0) {
            this.pasteStartAddress();
            if (!this.showPrice) {
              this.form.endAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          } else {
            this.pasteEndAddress();
            if (!this.showPrice) {
              this.form.startAddress && this.computeTotal();
            } else {
              this.computeTotal();
            }
          }
          this.cancelBtn();
        } else {
          this.$message.warning("请选择固定点！");
        }
      }
      this.marker = null;
    },
    cancelBtn () {
      this.showMap = false;
      this.marker = null;
      this.searchAddress = null;
      this.areaPath = [];
      this.addressList = [];
      this.searchInput = null;
      this.positionLatLng = null;
      this.pointList = [];
      this.allStationList = [];
      this.currentIndex = -1;
      if (this.map) this.map.destroy();
    },
    // 计算总价
    computeTotal () {
      this.ticketPrice = parseFloat(this.ticketPrice);
      this.upFloatingPrice = parseFloat(this.upFloatingPrice || 0);
      this.downFloatingPrice = parseFloat(this.downFloatingPrice || 0);
      let { servicePricesPer, passengerCount, usedPassengerVOS } = this.form;
      passengerCount = passengerCount || usedPassengerVOS.length;
      this.form.ticketPricesPer = (
        this.ticketPrice +
        this.upFloatingPrice +
        this.downFloatingPrice +
        servicePricesPer
      ).toFixed(2);
      //处理精度问题
      this.form.totalPrices =
        Math.round(this.form.ticketPricesPer * passengerCount * 100) / 100;
    },
    // 立即下单
    placeOrder () {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let obj = {
            businessType: 1,
            realName: this.form.realName,
            shiftIntercityId: this.form.shiftIntercityId,
            startPointLongitude: this.form.startPointLongitude,
            endPointLongitude: this.form.endPointLongitude,
            endPointLatitude: this.form.endPointLatitude,
            startPointLatitude: this.form.startPointLatitude,
            startAddress: this.form.startAddress,
            endAddress: this.form.endAddress,
            passengerPhone: this.form.passengerPhone,
            orderPhone: this.form.passengerPhone,
            passengerCount: this.form.passengerCount,
            startStationId: this.form.startStationId,
            startLineAreaId: this.form.startLineAreaId,
            startStationType: this.form.startStationType,
            endStationId: this.form.endStationId,
            endStationType: this.form.endStationType,
            remarks: this.form.remarks,
            autoSeatSelection: true,
          };
          if (this.headerText == "预留") {
            obj.payablePrices = this.form.totalPrices;
            obj.totalPrices = 0;
            obj.purchaseMethod = 4;
          } else {
            obj.totalPrices = this.form.totalPrices;
            obj.purchaseMethod = 1; //0-乘客购票 1-后台购票 2-快客码下单 3-API购票 4-预留
          }
          if (this.form.endLineAreaId)
            obj.endLineAreaId = this.form.endLineAreaId;
          if (this.form.endStationName)
            obj.endStationName = this.form.endStationName;
          if (!this.form.realName) {
            obj.carryingChildrenNum = this.form.carryingChildrenNum;
          } else {
            let arr = this.deepClone(this.form.usedPassengerVOS);
            let total = 0;
            obj.usedPassengerVOS = arr.map((item) => {
              if (item.idCardNo2) {
                item.idCardNo = this.encryptDes(item.idCardNo2);
              } else {
                item.idCardNo = this.encryptDes(item.idCardNo);
              }
              item.isCarryingChildren && (total += 1);
              return {
                idCardNo: item.idCardNo,
                segment: item.segment,
                userName: item.userName,
                isCarryingChildren: item.isCarryingChildren,
              };
            });
            obj.carryingChildrenNum = total;
          }
          //流水班需要再增加期望出行时间
          if (this.shiftType === 1) {
            obj.startAnticipationRidingTime =
              this.form.anticipationRidingTime?.split("-")[0];
            obj.endAnticipationRidingTime =
              this.form.anticipationRidingTime?.split("-")[1];
          } else {
            //定班：取班次时间
            let timestamp = new Date(
              this.currentShiftObj.startDepartureDateTime
            ).getTime();
            obj.startAnticipationRidingTime = timestamp;
            obj.endAnticipationRidingTime = timestamp;
          }
          //校验乘客数是否超过余票或者线路规则限制数量
          if (obj.passengerCount > this.lineOrderPassengerMax) {
            this.$message.warning(
              `乘客数超过一单可购票人数${this.lineOrderPassengerMax}人`
            );
            return;
          } else if (obj.passengerCount > this.currentShiftObj?.surplusTicket) {
            this.$message.warning("乘客数超过余票数");
            return;
          }
          this.loading = true;
          this.isFirstChange = false;
          //补票派车：派车才传司机订单号，不派车0或者不选都传null
          if (this.computedDriverOrderId) {
            obj.driverOrderId = this.form.radioValue
              ? this.form.radioValue
              : null;
          }
          this.submitOrder(obj); //下单
        }
      });
    },
    submitOrder (obj) {
      placeOrderAPI(obj)
        .then((res) => {
          if (res.code == "SUCCESS") {
            //缓存快捷上下车点
            const flag1 = this.upCatchList.some(
              (e) =>
                e.lng == this.selectStartPoint.lng &&
                e.lat == this.selectStartPoint.lat &&
                e.detialAddress.split("|")[1] ==
                this.selectStartPoint.detialAddress.split("|")[1]
            );
            const flag2 = this.downCatchList.some(
              (e) =>
                e.lng == this.selectEndPoint.lng &&
                e.lat == this.selectEndPoint.lat &&
                e.detialAddress.split("|")[1] ==
                this.selectEndPoint.detialAddress.split("|")[1]
            );
            if (!flag1) {
              this.catchUpAddress(this.selectStartPoint);
            }
            if (!flag2) {
              this.catchDownAddress(this.selectEndPoint);
            }
            const data = {
              type: 3,
              code: obj.passengerPhone + "-" + this.lineId,
              cacheDate: {
                upCatch: this.copyUpCatchList,
                downCatch: this.copyDownCatchList,
              },
            };
            if (this.cacheId) {
              data.id = this.cacheId;
            }
            saveOrUpdate(data).then((res) => { });
            if (this.headerText == "预留") {
              this.$message.success("预留成功！");
              this.$emit("saveOk");
              this.loading = false;
            } else {
              this.outTradeNo = res.data;
              this.payStatus = 1;
              this.queryCancelTime();
              this.$message.success("下单成功，请尽快支付");
            }
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //查询订单取消时间
    queryCancelTime () {
      configureParamsAPI({
        lineId: this.lineId,
        channelNO: this.channelNO,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.lockTime = JSON.parse(res.data.ORDER_LOCK_TIME).regulationValue;
          this.countTime(new Date());
          this.payOrder();
        }
      });
    },
    countTime (createTime) {
      if (this.payTimer) return;
      let newCreate = new Date();
      let totals = parseInt((newCreate - createTime) / 1000);
      if (totals >= this.lockTime * 60) {
        this.cancelOrder();
      } else {
        this.computeCancelTime(totals);
        this.payTimer = setInterval(() => {
          let newCreate = new Date();
          let totals = parseInt((newCreate - createTime) / 1000);
          //如果超过订单锁定时间
          if (totals >= this.lockTime * 60) {
            this.payCodeShow = false;
            this.cancelOrder(); //如果倒计时结束，就调用取消订单接口
            clearInterval(this.payTimer);
            if (this.payCodeTimer) clearInterval(this.payCodeTimer);
          } else {
            this.computeCancelTime(totals);
            this.renderData(this.outTradeNo);
            if (this.form.orderStatus >= 20 && this.form.orderStatus <= 70) {
              this.result = 1;
              clearInterval(this.payTimer);
              if (this.payCodeTimer) clearInterval(this.payCodeTimer);
            }
          }
        }, 1000);
      }
    },
    computeCancelTime (totals) {
      let rs = this.lockTime * 60 - totals;
      let h = parseInt(rs / 60);
      let s = rs % 60;
      if (h < 10) h = "0" + h;
      if (s < 10) s = "0" + s;
      this.cancelTime = `${h}分${s}秒`;
    },
    renderData (id) {
      const obj = {
        orderId: id,
        currentPage: 1,
        pageSize: 10,
      };
      getPhoneOrderListAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.form.orderStatus = res.data.list[0]?.orderStatus;
          if (this.form.orderStatus == 10) {
            this.payStatus = 1;
            let radioValue = this.form.radioValue;
            if (this.isAwaitPay) {
              this.form = res.data.list[0];
              this.form.radioValue = radioValue;
              this.form.ticketPricesPer =
                this.form.ticketPricesPer + this.form.servicePricesPer;
              this.outTradeNo = this.form.orderId;
              this.form.realName = res.data.list[0].isRealName ? true : false;
              this.form.shiftIntercityId = this.computedDepartureTime(
                this.form
              ); //回显班次时间
              this.computedDaysText(this.form.departureDate);
              if (this.form.shiftType === 1) {
                this.form.anticipationRidingTime = `${this.form.startAnticipationRidingTime.substring(
                  11,
                  16
                )}-${this.form.endAnticipationRidingTime.substring(11, 16)}`;
              }
              this.countTime(new Date(this.form.createTime));
            }
          } else if (
            this.form.orderStatus >= 20 &&
            this.form.orderStatus <= 70
          ) {
            this.$message.success("支付成功");
            this.payStatus = 3;
            this.closeFun();
          } else {
            this.payStatus = 2;
            this.closeFun();
          }
        }
      });
    },
    payOrder () {
      this.payCodeShow = true;
      this.QRcodeTimer();
      if (!this.scanningTime) {
        this.coverShow = false;
        this.scannCode();
      }
    },
    // 取消订单
    cancelOrder () {
      cancelOrderAPI({ orderId: this.outTradeNo }).then((res) => {
        if (res.code == "SUCCESS") {
          this.payStatus = 2;
          this.cancelTime = "";
          this.scanningTime = null;
          this.qrcode = "";
          this.payCodeShow = false;
          this.$message("订单已取消");
          this.closeFun();
        }
      });
    },
    // 生成扫码倒计时
    scannCode () {
      const createTime = new Date();
      this.scanningTime = `01分00秒`;
      this.payCodeTimer = setInterval(() => {
        let newCreate = new Date();
        let totals = parseInt((newCreate - createTime) / 1000);
        let minutes = parseInt(totals / 60);
        let resultMinutes = "00";
        let resultSeconds = (minutes + 1) * 60 - totals;
        if (resultSeconds < 10) resultSeconds = "0" + resultSeconds;
        this.scanningTime = `${resultMinutes}分${resultSeconds}秒`;
        if (!(resultSeconds % 60)) {
          this.coverShow = true;
          resultSeconds = "00";
          this.scanningTime = null;
          clearInterval(this.payCodeTimer);
        }
      }, 1000);
    },
    QRcodeTimer () {
      payOrderAPI({
        businessType: 1,
        outTradeNo: this.outTradeNo,
      }).then((result) => {
        if (result.code == "SUCCESS") {
          this.qrcode = result.data;
          this.$nextTick(() => {
            this.createQRCode(this.qrcode);
          });
        }
      });
    },
    createQRCode (code) {
      this.$refs.qrcode.innerHTML = "";
      new QRCode(this.$refs.qrcode, {
        text: code, // 需要转换为二维码的内容
        width: 290,
        height: 290,
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
    },
    reloadQR () {
      this.coverShow = false;
      this.QRcodeTimer();
      this.scannCode();
    },
    payCodeClose () {
      this.payCodeShow = false;
    },
    //状态
    computedStatus (status) {
      let statusText = "";
      if (status === 40) {
        statusText = "已完成";
      } else if (20 < status && status < 40) {
        statusText = "行程中";
      } else if (status <= 20) {
        statusText = "待发车";
      }
      return statusText;
    },
    computedClass (status) {
      let calss = "";
      if (status === 40) {
        calss = "card-status-finish";
      } else if (20 < status && status < 40) {
        calss = "card-status-going";
      } else if (status <= 20) {
        calss = "card-status-waiting";
      }
      return calss;
    },
  },
};
</script>

<style lang="scss" scoped>
.safe-tips {
  line-height: 36px;
  color: #666;
  .title {
    font-size: 18px;
    color: #333;
    margin-bottom: 12px;
  }
}
.telebooking {
  z-index: 777;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

  .calling {
    padding: 20px;
    width: 820px;
    max-height: 95vh;
    background: #ffffff;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    header {
      font-size: 15px;
      margin-bottom: 15px;
    }
    /deep/.el-form-item {
      margin-bottom: 20px;
    }
    .form-main {
      flex: 1;
      overflow: auto;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      .label {
        background-color: #f7f8f9;
        border-left: 2px solid #336ffe;
        padding: 5px 0px 5px 21px;
        margin-bottom: 8px;
      }
      .its_gy_delete {
        color: red;
        margin-left: 10px;
        cursor: pointer;
        font-size: 18px;
      }
      .add {
        width: 470px;
        height: 32px;
        border: #dcdedf dashed 1px;
        margin-bottom: 10px;
        cursor: pointer;
        margin-left: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(131, 131, 131);
      }
      .editbox {
        display: flex;
        .its_gy_map {
          color: #336ffe;
          margin-left: 10px;
          cursor: pointer;
          font-size: 18px;
        }
      }
      ul {
        display: flex;
        line-height: 22px;
        li {
          margin-right: 8px;
          background-color: #cedcff;
          font-size: 12px;
          padding: 2px 8px;
          border-radius: 40px;
          cursor: pointer;
          height: max-content;
        }
      }
    }
    .pay-money {
      background-color: #f3f6ff;
      padding: 15px 23px;
      font-size: 14px;
      margin: 10px 0;
      position: relative;
      .total-detail {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .item {
          color: #858585;
          margin-right: 40px;
        }
      }
      .total-price {
        font-weight: bold;
        display: flex;
        align-items: center;
        .price {
          color: red;
          font-size: 24px;
        }
      }
      .pay-text {
        position: absolute;
        color: white;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
        font-size: 24px;
        right: 50px;
      }
      .pay-flag {
        width: 120px;
        height: 50px;
        color: white;
        position: absolute;
        right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        top: 50%;
        transform: translateY(-50%) rotate(45deg) skew(-45deg);
      }
      .pay-flag1 {
        background-color: #ff8627;
      }
      .pay-flag2 {
        background-color: #d8d8d8;
      }
      .pay-flag3 {
        background-color: #2bc505;
      }
    }
    .pay-money1 {
      background-color: rgba(255, 134, 39, 0.1);
    }
    .pay-money2 {
      background-color: rgba(216, 216, 216, 0.1);
    }
    .pay-money3 {
      background-color: rgba(43, 197, 5, 0.1);
    }
    .cancel-time {
      font-weight: bold;
      margin-bottom: 10px;
      margin-left: 23px;
      .time {
        color: red;
      }
      i {
        color: red;
        margin-right: 5px;
      }
    }
    .footer-self {
      text-align: right;
    }
  }
  .scanning {
    padding: 20px;
    color: black;
    font-weight: 400;
    header {
      span {
        &:nth-child(1) {
          font-size: 16px;
          margin-right: 24px;
        }
        &:nth-child(3) {
          color: red;
        }
      }
    }
    .middle {
      display: flex;
      align-items: center;
      padding: 30px;
      justify-content: center;
      .qrcode {
        .money {
          text-align: center;
          margin-bottom: 5px;
          span {
            &:nth-child(2) {
              color: red;
              font-size: 20px;
            }
          }
        }
        #image {
          padding: 22px;
          width: 336px;
          height: 336px;
          box-sizing: border-box;
          border: 1px solid rgba(0, 0, 0, 0.1);
        }
        .transprant {
          width: 336px;
          height: 336px;
          background-color: transparent;
          margin-top: -336px;
          position: relative;
          z-index: 99;
        }
        .cover {
          width: 336px;
          height: 336px;
          background-color: rgba(0, 0, 0, 0.6);
          margin-top: -336px;
          color: white;
          display: flex;
          justify-content: center;
          position: relative;
          z-index: 999;
          align-items: center;
          button {
            background-color: #fff;
            border: none;
            outline: none;
            padding: 30px 15px;
            cursor: pointer;
          }
        }
        .explain {
          margin-top: 10px;
          width: 336px;
          height: 50px;
          background: rgba(0, 199, 119, 0.1);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #2bc505;
          font-size: 16px;
          i {
            margin-right: 20px;
          }
        }
      }
      .weixin {
        margin-left: 38px;
        img {
          width: 225px;
          height: 287px;
        }
      }
    }
  }
  .result {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    .type {
      display: flex;
      flex-direction: column;
      align-items: center;
      span {
        color: #2bc505;
        margin-bottom: 12px;
        font-size: 24px;
      }
    }
  }
  .map {
    position: relative;
    height: 500px;
    .searchbox {
      position: absolute;
      top: 14px;
      left: 10px;
      width: 300px;
      z-index: 1200;
      input {
        width: 212px;
        height: 28px;
        border: #d6d6d6 solid 1px;
        flex: 1;
        outline: none;
        margin-right: 16px;
        padding-left: 10px;
      }
      .pointlist {
        max-height: 250px;
        background-color: #fff;
        overflow: auto;
        margin-top: 10px;
        p {
          padding: 7px 10px;
          cursor: pointer;
          &:hover {
            background-color: #e4e4e4;
          }
        }
      }
    }
    .select {
      width: 300px;
      position: absolute;
      z-index: 1100;
      left: 10px;
      top: 55px;
      background-color: #fff;
      border-radius: 5px;
      max-height: 400px;
      overflow-y: auto;
      header {
        font-size: 15px;
        color: black;
        padding: 10px 10px 0;
      }
      p {
        margin-top: 10px;
        padding: 0 10px 10px;
      }
      .point {
        margin: 10px 0;
        .item {
          border-radius: 5px;
          cursor: pointer;
          padding: 10px 10px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //溢出用省略号显示
          white-space: nowrap; //溢出不换行
          .detial {
            margin-left: 15px;
            font-size: 12px;
          }
        }
        .item1 {
          color: white;
          background-color: #336ffe;
        }
      }
    }
    #container {
      height: 500px;
    }
  }
  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    .desc {
      color: red;
      margin-right: 30px;
      font-size: 15px;
    }
  }
  /deep/.el-radio-group {
    width: 100%;
  }
  .radio-row {
    width: 100%;
    /deep/.el-radio {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 16px 0 0;
      .el-radio__label {
        width: 100%;
        display: flex;
        align-items: center;
        .name {
          width: 96px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .phone {
          width: 100px;
          margin: 0 8px;
        }
        .cph {
          width: 90px;
        }
        .sur {
          margin: 0 8px;
          width: 60px;
          span {
            color: red;
            margin-left: 4px;
            vertical-align: middle;
          }
        }
      }
      .card-status {
        font-size: 12px;
        border-radius: 4px;
        padding: 2px 4px;
        width: 58px;
        text-align: center;
        line-height: 20px !important;
      }
      .card-status-finish {
        color: #ffffff;
        background: #336ffe;
      }
      .card-status-going {
        background: #00c777;
        color: #ffffff;
      }
      .card-status-waiting {
        background: #ff8627;
        color: #ffffff;
      }
    }
  }
  .tips {
    color: #666666;
    font-size: 14px;
    background-color: rgba(247, 208, 181, 0.2);
    border-radius: 6px;
    padding: 12px 16px;
    box-sizing: border-box;
  }
  .el-icon-warning {
    color: #999;
    font-size: 16px;
    margin-right: 6px;
  }
}
</style>
