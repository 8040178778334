var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "telebooking" },
    [
      _c("div", { staticClass: "calling" }, [
        _c("header", [_vm._v(_vm._s(_vm.headerText))]),
        _c(
          "div",
          { staticClass: "form-main" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                attrs: {
                  model: _vm.form,
                  disabled: _vm.payStatus ? true : false,
                  rules: _vm.rules,
                  "label-position": "right",
                  "label-width": "120px",
                },
              },
              [
                _c("div", { staticClass: "label" }, [_vm._v("乘客信息")]),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 18 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "下单人电话：",
                              prop: "passengerPhone",
                            },
                          },
                          [
                            _c("el-input", {
                              style: {
                                width:
                                  _vm.headerText == "预留" ? "80%" : "100%",
                              },
                              attrs: {
                                maxlength: "11",
                                placeholder: "请输入来电手机号",
                              },
                              on: { input: _vm.inputPhone },
                              model: {
                                value: _vm.form.passengerPhone,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "passengerPhone", $$v)
                                },
                                expression: "form.passengerPhone",
                              },
                            }),
                            _vm.headerText == "预留"
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "margin-left": "8px",
                                      color: "#e74848",
                                    },
                                    on: { click: _vm.popTips },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-warning",
                                      staticStyle: { color: "#e74848" },
                                    }),
                                    _vm._v("注意事项 "),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "购票类型：", prop: "realName" } },
                          [
                            _c(
                              "el-radio",
                              {
                                attrs: { disabled: "", label: false },
                                model: {
                                  value: _vm.form.realName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "realName", $$v)
                                  },
                                  expression: "form.realName",
                                },
                              },
                              [_vm._v("匿名")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { disabled: "", label: true },
                                model: {
                                  value: _vm.form.realName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "realName", $$v)
                                  },
                                  expression: "form.realName",
                                },
                              },
                              [_vm._v("实名")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "label" }, [_vm._v("行程信息")]),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "出发地：", prop: "startStation" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                on: {
                                  focus: _vm.selectStartStation,
                                  change: function ($event) {
                                    return _vm.changeStation(0)
                                  },
                                },
                                model: {
                                  value: _vm.form.startStation,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "startStation", $$v)
                                  },
                                  expression: "form.startStation",
                                },
                              },
                              _vm._l(_vm.startStationList, function (item) {
                                return _c("el-option", {
                                  key: item.stationName,
                                  attrs: {
                                    label: item.stationName,
                                    value: item.stationName,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "到达地：", prop: "endStation" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  clearable: "",
                                  disabled: "",
                                },
                                on: {
                                  focus: _vm.selectEndStation,
                                  change: function ($event) {
                                    return _vm.changeStation(1)
                                  },
                                },
                                model: {
                                  value: _vm.form.endStation,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "endStation", $$v)
                                  },
                                  expression: "form.endStation",
                                },
                              },
                              _vm._l(_vm.endStationList, function (item) {
                                return _c("el-option", {
                                  key: item.stationName,
                                  attrs: {
                                    label: item.stationName,
                                    value: item.stationName,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 9 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "出行日期：",
                              prop: "departureDate",
                            },
                          },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                format: "yyyy-MM-dd",
                                placeholder: "请选择出行时间",
                                clearable: false,
                              },
                              on: { change: _vm.changeTimeSelect },
                              model: {
                                value: _vm.form.departureDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "departureDate", $$v)
                                },
                                expression: "form.departureDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { "label-width": "10px" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                on: { input: _vm.changedepartureDate },
                                model: {
                                  value: _vm.form.timeSelect,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "timeSelect", $$v)
                                  },
                                  expression: "form.timeSelect",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: 1 } }, [
                                  _vm._v("今天"),
                                ]),
                                _c("el-radio", { attrs: { label: 2 } }, [
                                  _vm._v("明天"),
                                ]),
                                _c("el-radio", { attrs: { label: 3 } }, [
                                  _vm._v("后天"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 10 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "班次时间：",
                              prop: "shiftIntercityId",
                            },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                on: {
                                  focus: _vm.selectShift,
                                  change: _vm.confirmShift,
                                },
                                model: {
                                  value: _vm.form.shiftIntercityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shiftIntercityId", $$v)
                                  },
                                  expression: "form.shiftIntercityId",
                                },
                              },
                              _vm._l(_vm.shiftList, function (item) {
                                return _c("el-option", {
                                  key: item.shiftIntercityId,
                                  attrs: {
                                    disabled: item.isDisabled,
                                    label: item.label,
                                    value: item.shiftIntercityId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.shiftType === 1
                      ? _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "期望出行时间：",
                                  prop: "anticipationRidingTime",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { width: "80px" },
                                    model: {
                                      value: _vm.form.anticipationRidingTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "anticipationRidingTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.anticipationRidingTime",
                                    },
                                  },
                                  _vm._l(
                                    _vm.anticipationRidingTimeList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.shiftIntercityId
                      ? _c("span", { staticStyle: { "margin-left": "10px" } }, [
                          _vm._v(" 此班次剩余携童票 "),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                color: "red",
                                "line-height": "36px",
                              },
                            },
                            [_vm._v(_vm._s(_vm.surplusCarryChildren))]
                          ),
                          _vm._v(" 张 "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.form.realName
                  ? [
                      _vm._l(_vm.form.usedPassengerVOS, function (item, index) {
                        return _c(
                          "el-row",
                          { key: index },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: index == 0 ? "乘客：" : "",
                                      prop:
                                        "usedPassengerVOS." +
                                        index +
                                        ".userName",
                                      rules: {
                                        required: true,
                                        message: "请输入乘客姓名",
                                        trigger: "blur",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "乘客姓名" },
                                      model: {
                                        value: item.userName,
                                        callback: function ($$v) {
                                          _vm.$set(item, "userName", $$v)
                                        },
                                        expression: "item.userName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 7 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      "label-width": "20px",
                                      prop:
                                        "usedPassengerVOS." +
                                        index +
                                        ".idCardNo",
                                      rules: {
                                        required: true,
                                        validator: (rule, value, callback) => {
                                          _vm.checkIdentificationNumber(
                                            rule,
                                            value,
                                            callback
                                          )
                                        },
                                        trigger: "blur",
                                      },
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { placeholder: "乘客身份证号" },
                                      model: {
                                        value: item.idCardNo,
                                        callback: function ($$v) {
                                          _vm.$set(item, "idCardNo", $$v)
                                        },
                                        expression: "item.idCardNo",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 4 } },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop:
                                        "usedPassengerVOS." +
                                        index +
                                        ".segment",
                                      "label-width": "20px",
                                      rules: {
                                        required: true,
                                        message: "请选择乘客类型",
                                        trigger: "blur",
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        on: {
                                          change: (e) =>
                                            _vm.selectSegment(e, item),
                                        },
                                        model: {
                                          value: item.segment,
                                          callback: function ($$v) {
                                            _vm.$set(item, "segment", $$v)
                                          },
                                          expression: "item.segment",
                                        },
                                      },
                                      [
                                        _c("el-option", {
                                          attrs: { value: 1, label: "成人" },
                                        }),
                                        _c("el-option", {
                                          attrs: { value: 0, label: "儿童" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-col",
                              { attrs: { span: 5 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "20px" } },
                                  [
                                    item.segment
                                      ? _c(
                                          "el-checkbox",
                                          {
                                            on: {
                                              change: function ($event) {
                                                return _vm.clickChildren(index)
                                              },
                                            },
                                            model: {
                                              value: item.isCarryingChildren,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  item,
                                                  "isCarryingChildren",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "item.isCarryingChildren",
                                            },
                                          },
                                          [_vm._v("携童")]
                                        )
                                      : _vm._e(),
                                    _vm.form.usedPassengerVOS.length > 1
                                      ? _c("i", {
                                          staticClass: "iconfont its_gy_delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteVisitor(index)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _vm.isFirstChange
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                color: "red",
                                "margin-bottom": "8px",
                                "text-align": "center",
                              },
                            },
                            [_vm._v(" 携童数发生改变请重新选择 ")]
                          )
                        : _vm._e(),
                      !_vm.payStatus
                        ? _c(
                            "div",
                            { staticClass: "add", on: { click: _vm.addNew } },
                            [
                              _c("i", {
                                staticClass: "iconfont its_tianjia",
                                staticStyle: { "margin-right": "10px" },
                              }),
                              _vm._v(" 添加新乘客 "),
                            ]
                          )
                        : _vm._e(),
                    ]
                  : _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "乘客人数：",
                                  prop: "passengerCount",
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    size: "small",
                                    min: 1,
                                    max: _vm.computedPassengerCountMax,
                                  },
                                  on: { change: _vm.selectValPer },
                                  model: {
                                    value: _vm.form.passengerCount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "passengerCount", $$v)
                                    },
                                    expression: "form.passengerCount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "携童数量：",
                                  prop: "carryingChildrenNum",
                                },
                              },
                              [
                                _c("el-input-number", {
                                  attrs: {
                                    size: "small",
                                    min: 0,
                                    max:
                                      _vm.form.passengerCount >=
                                      _vm.surplusCarryChildren
                                        ? _vm.surplusCarryChildren
                                        : _vm.form.passengerCount,
                                  },
                                  model: {
                                    value: _vm.form.carryingChildrenNum,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "carryingChildrenNum",
                                        $$v
                                      )
                                    },
                                    expression: "form.carryingChildrenNum",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.isFirstChange
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "red",
                                  "line-height": "36px",
                                  "margin-left": "10px",
                                },
                              },
                              [_vm._v(" 携童数发生改变请重新选择 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 19 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "上车点：",
                              prop: _vm.addressRequired ? "startAddress" : "",
                            },
                          },
                          [
                            _c("div", { staticClass: "address" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "editbox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectAddress(0)
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请选择上车点" },
                                    model: {
                                      value: _vm.form.startAddress,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "startAddress", $$v)
                                      },
                                      expression: "form.startAddress",
                                    },
                                  }),
                                  _vm.payStatus === 0 && !_vm.isAwaitPay
                                    ? _c("i", {
                                        staticClass: "iconfont its_gy_map",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.payStatus === 0 && !_vm.isAwaitPay
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.upCatchList,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectCatchAddress(
                                                  item,
                                                  0
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.address) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 19 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "下车点：",
                              prop: _vm.addressRequired ? "endAddress" : "",
                            },
                          },
                          [
                            _c("div", { staticClass: "address" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "editbox",
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectAddress(1)
                                    },
                                  },
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请选择上车点" },
                                    model: {
                                      value: _vm.form.endAddress,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "endAddress", $$v)
                                      },
                                      expression: "form.endAddress",
                                    },
                                  }),
                                  _vm.payStatus === 0 && !_vm.isAwaitPay
                                    ? _c("i", {
                                        staticClass: "iconfont its_gy_map",
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm.payStatus === 0 && !_vm.isAwaitPay
                                ? _c(
                                    "ul",
                                    _vm._l(
                                      _vm.downCatchList,
                                      function (item, index) {
                                        return _c(
                                          "li",
                                          {
                                            key: index,
                                            on: {
                                              click: function ($event) {
                                                return _vm.selectCatchAddress(
                                                  item,
                                                  1
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.address) + " "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 19 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注：" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入备注",
                                type: "textarea",
                              },
                              model: {
                                value: _vm.form.remarks,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "remarks", $$v)
                                },
                                expression: "form.remarks",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.computedDriverOrderId
                  ? _c("div", { staticClass: "label" }, [_vm._v("补票派车")])
                  : _vm._e(),
                _vm.computedDriverOrderId
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "", prop: "radioValue" } },
                              [
                                _vm.radioArray.length
                                  ? _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.handleRadioChanges },
                                        model: {
                                          value: _vm.form.radioValue,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "radioValue",
                                              $$v
                                            )
                                          },
                                          expression: "form.radioValue",
                                        },
                                      },
                                      [
                                        [
                                          _c(
                                            "el-row",
                                            {
                                              staticStyle: {
                                                "padding-top": "20px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                { key: 0, attrs: { label: 0 } },
                                                [_vm._v("不派车 ")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        _vm._l(_vm.radioArray, function (opt) {
                                          return _c(
                                            "div",
                                            {
                                              key: opt.id,
                                              staticClass: "radio-row",
                                            },
                                            [
                                              _c(
                                                "el-radio",
                                                {
                                                  attrs: {
                                                    label: opt.id,
                                                    disabled: opt.disabled,
                                                  },
                                                },
                                                [
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "name" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(opt.driverName)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "phone" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            opt.driverPhone
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "cph" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            opt.licensePlateNumber
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "sur" },
                                                      [
                                                        _vm._v(" 余座"),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              opt.surplusCarryPassengerNumber
                                                            )
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        class: [
                                                          "card-status",
                                                          _vm.computedClass(
                                                            opt.orderStatus
                                                          ),
                                                        ],
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.computedStatus(
                                                                opt.orderStatus
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }),
                                      ],
                                      2
                                    )
                                  : [
                                      _c("div", { staticClass: "tips" }, [
                                        _c("i", {
                                          staticClass: "el-icon-warning",
                                        }),
                                        _vm._v(
                                          "该班次未指派车辆和驾驶员，下单支付后请及时指派车辆和驾驶员。 "
                                        ),
                                      ]),
                                    ],
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "pay-money",
            class: {
              "pay-money1": _vm.payStatus == 1,
              "pay-money2": _vm.payStatus == 2,
              "pay-money3": _vm.payStatus == 3,
            },
          },
          [
            _c("div", { staticClass: "total-detail" }, [
              _c("div", { staticClass: "item" }, [
                _vm._v("票价：" + _vm._s(_vm.computedTicktPrice) + " 元/张"),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm._v(
                  "服务费：" + _vm._s(_vm.form.servicePricesPer) + " 元/张"
                ),
              ]),
              _c("div", { staticClass: "item" }, [
                _vm._v("乘坐人数：" + _vm._s(_vm.form.passengerCount) + " 人"),
              ]),
            ]),
            _c("div", { staticClass: "total-price" }, [
              _vm._v(" 应付金额： "),
              _c("div", { staticClass: "price" }, [
                _vm._v(_vm._s(_vm.form.totalPrices)),
              ]),
            ]),
            _vm.payStatus
              ? _c("div", {
                  staticClass: "pay-flag",
                  class: {
                    "pay-flag1": _vm.payStatus == 1,
                    "pay-flag2": _vm.payStatus == 2,
                    "pay-flag3": _vm.payStatus == 3,
                  },
                })
              : _vm._e(),
            _vm.payStatus
              ? _c("div", { staticClass: "pay-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.payStatus == 1
                          ? "待支付"
                          : _vm.payStatus == 2
                          ? "已取消"
                          : "已完成"
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        ),
        _vm.payStatus == 1
          ? _c("p", { staticClass: "cancel-time" }, [
              _c("i", { staticClass: "el-icon-info" }),
              _c("span", [_vm._v("请在")]),
              _c("span", { staticClass: "time" }, [
                _vm._v(_vm._s(_vm.cancelTime)),
              ]),
              _c("span", [_vm._v("内完成支付，逾期将自动取消订单")]),
            ])
          : _vm._e(),
        !_vm.payStatus
          ? _c(
              "div",
              { staticClass: "footer-self" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", disabled: _vm.loading },
                    on: { click: _vm.placeOrder },
                  },
                  [_vm._v("立即下单")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "default" }, on: { click: _vm.closeFun } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            )
          : _vm.payStatus == 1
          ? _c(
              "div",
              { staticClass: "footer-self" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.payOrder } },
                  [_vm._v("立即支付")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "default" },
                    on: { click: _vm.cancelOrder },
                  },
                  [_vm._v("取消订单")]
                ),
                _c(
                  "el-button",
                  { attrs: { type: "default" }, on: { click: _vm.closeFun } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "footer-self" },
              [
                _c(
                  "el-button",
                  { attrs: { type: "default" }, on: { click: _vm.closeFun } },
                  [_vm._v("关闭")]
                ),
              ],
              1
            ),
      ]),
      _vm.payCodeShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                visible: _vm.payCodeShow,
                title: "下单支付",
                width: "700px",
                "close-on-click-modal": false,
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.payCodeShow = $event
                },
                close: _vm.payCodeClose,
              },
            },
            [
              !_vm.result
                ? _c("div", { staticClass: "scanning" }, [
                    _vm.payStatus == 1 && !_vm.coverShow
                      ? _c("header", [
                          _c("span", [_vm._v("微信支付")]),
                          _c("span", [_vm._v("请在")]),
                          _c("span", [_vm._v(_vm._s(_vm.scanningTime))]),
                          _c("span", [_vm._v("内扫码支付")]),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "middle" }, [
                      _c("div", { staticClass: "qrcode" }, [
                        _c("div", { staticClass: "money" }, [
                          _c("span", [_vm._v("支付金额：")]),
                          _c("span", [_vm._v(_vm._s(_vm.form.totalPrices))]),
                        ]),
                        _c("div", { ref: "qrcode", attrs: { id: "image" } }),
                        _c("div", { staticClass: "transprant" }),
                        _vm.coverShow
                          ? _c("div", { staticClass: "cover" }, [
                              _c("button", { on: { click: _vm.reloadQR } }, [
                                _vm._v("二维码失效，点击重新获取二维码"),
                              ]),
                            ])
                          : _vm._e(),
                        _c("div", { staticClass: "explain" }, [
                          _c("i", { staticClass: "iconfont its_gouxuan" }),
                          _c("span", [_vm._v("请使用微信扫描二维码支付")]),
                        ]),
                      ]),
                      _c("div", { staticClass: "weixin" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/home/scanning.png"),
                            alt: "",
                          },
                        }),
                      ]),
                    ]),
                  ])
                : _c("div", { staticClass: "result" }, [
                    _vm.result == 1
                      ? _c("div", { staticClass: "type" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/success.png"),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v("支付成功")]),
                          _c("p", [
                            _vm._v("您已支付成功，请关闭窗口查看订单详情。"),
                          ]),
                        ])
                      : _c("div", { staticClass: "type" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/home/fail.png"),
                              alt: "",
                            },
                          }),
                          _c("span", { staticStyle: { color: "red" } }, [
                            _vm._v("支付失败"),
                          ]),
                          _c("p", [_vm._v("支付失败，请关闭窗口重新提交。")]),
                        ]),
                  ]),
            ]
          )
        : _vm._e(),
      _vm.showMap
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.addressType ? "选择下车点" : "选择上车点",
                visible: _vm.showMap,
                width: "1000px",
                "close-on-click-modal": false,
                "modal-append-to-body": false,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.showMap = $event
                },
                close: _vm.cancelBtn,
              },
            },
            [
              _c("div", { staticClass: "map" }, [
                _c("div", { staticClass: "searchbox" }, [
                  _c(
                    "header",
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.searchInput,
                            expression: "searchInput",
                          },
                        ],
                        attrs: { type: "text", placeholder: "搜索地址" },
                        domProps: { value: _vm.searchInput },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.searchInput = $event.target.value
                          },
                        },
                      }),
                      _c(
                        "el-button",
                        { attrs: { type: "primary", size: "small" } },
                        [_vm._v("搜索")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "pointlist" },
                    _vm._l(_vm.addressList, function (item, index) {
                      return _c(
                        "p",
                        {
                          key: index,
                          on: {
                            click: function ($event) {
                              return _vm.searchClick(item, _vm.addressType)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.address) + " ")]
                      )
                    }),
                    0
                  ),
                ]),
                _c("div", { staticClass: "select" }, [
                  !_vm.searchInput
                    ? _c("div", [
                        _c("header", [_vm._v("请选择地址")]),
                        _c(
                          "div",
                          { staticClass: "point" },
                          _vm._l(_vm.allStationList, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "item",
                                class: { item1: _vm.currentIndex === index },
                                on: {
                                  click: function ($event) {
                                    return _vm.clickArea(item, index)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
                _c("div", { attrs: { id: "container" } }),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: { click: _vm.cancelBtn },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.confirmAddress },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.tipsdialogVisible,
            width: "700px",
            "append-to-body": true,
            top: "30vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.tipsdialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "safe-tips" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " 为了满足监管要求，即日起（3月18日起），使用预留结合快客码付款的线路注意： "
              ),
            ]),
            _c("div", [_vm._v("1、预留票的预留手机号码必须是乘客号码")]),
            _c("div", [
              _vm._v(
                " 2、乘客扫码的微信必须是预留票手机注册，否则使用快客码时无法获取到预留订单并支付 "
              ),
            ]),
            _c("div", [
              _vm._v(
                "3、预留票订单初始手机号码不是乘客手机号码时，允许调度进行修改"
              ),
            ]),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.tipsdialogVisible = false
                    },
                  },
                },
                [_vm._v("知道了")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }